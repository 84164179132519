import { computed, provide, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika/src/constants/index';
import RouteName from '@leon-hub/routing-config-names';
import { FormControlType, FormState } from 'web/src/components/Form/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import getUiSchema from 'web/src/modules/support/submodules/callback/pages/SupportCallbackRoutePage/utils/getUiSchema';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { YMCallSupportActions } from 'web/src/modules/support/submodules/callback/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import useSupportCallbackStore from 'web/src/modules/support/submodules/callback/store/useSupportCallbackStore';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { isFormPhoneValue } from 'web/src/components/Form/guards';
import formPhoneValueToApiPhoneInput from 'web/src/components/Input/utils/formPhoneValueToApiPhoneInput';
export default function useSupportCallback() {
    const siteConfigStore = useSiteConfigStore();
    const supportCallbackStore = useSupportCallbackStore();
    const customerDataStore = useCustomerDataStore();
    const analytics = useAnalytics();
    const router = useRouter();
    const { closeDialog, showDialog } = useDialogs();
    const { $translate } = useI18n();
    const { getDefaultPhoneValueForForm } = useFormDefaultFormPhoneValue();
    const phoneDecodedStringFull = toRef(customerDataStore, 'phoneDecodedStringFull');
    const formState = ref(FormState.INITIAL);
    const cmsData = ref({
        isCallbackFormEnabled: false,
        phone: ''
    });
    let successModalId = '';
    const isPending = computed(()=>formState.value === FormState.PENDING);
    const supportBlock = toRef(siteConfigStore, 'supportBlock');
    const isCallbackFormEnabled = computed(()=>!!supportBlock.value?.callCenterCallbackEnabled);
    const phoneProductProperties = computed(()=>({
            widget: FormControlType.Phone,
            default: getDefaultPhoneValueForForm(),
            options: {
                placeholder: $translate('WEB2_PHONE_INPUT_PLACEHOLDER').value,
                emitFilled: true
            }
        }));
    const uiSchema = computed(()=>getUiSchema(formState.value, phoneProductProperties.value, $translate));
    watch(isCallbackFormEnabled, (newValue)=>{
        cmsData.value.isCallbackFormEnabled = newValue;
    }, {
        immediate: true
    });
    watch(phoneDecodedStringFull, (newValue)=>{
        cmsData.value.phone = newValue;
    }, {
        immediate: true
    });
    provide('cmsData', cmsData.value);
    function yandexMetrikaCallSupport(action) {
        analytics.push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
            supportDetails: {
                callSupport: {
                    [action]: true
                }
            }
        });
    }
    function runOnMounted() {
        yandexMetrikaCallSupport(YMCallSupportActions.OPEN_CALL_SUPPORT);
    }
    function runOnBeforeUnmount() {
        if (!successModalId) closeDialog(successModalId);
    }
    function showResponseModal() {
        const commonProps = {
            confirmMessage: $translate('WEB2_CALLBACK_SUCCESS_MESSAGE').value,
            title: $translate('WEB2_CALLBACK_SUCCESS_TITLE').value,
            fullHeight: true,
            dataTestId: 'callback-success'
        };
        "1";
        {
            const { id, subscribe } = showDialog({
                presetName: PresetName.ALERT_SUCCESS,
                options: commonProps
            });
            successModalId = id;
            subscribe({
                [DialogAction.MODAL_CLOSE]: ()=>{
                    router.push({
                        name: RouteName.SUPPORT
                    });
                }
            });
        }
    }
    async function onSubmit(data) {
        if (!data.errors && !data.customErrors) {
            formState.value = FormState.PENDING;
            yandexMetrikaCallSupport(YMCallSupportActions.ORDER_CALL);
            const { phone } = data.formData;
            isFormPhoneValue(phone);
            try {
                await supportCallbackStore.requestCallback(formPhoneValueToApiPhoneInput(phone));
                formState.value = FormState.SUCCESS;
                showResponseModal();
            } catch (error) {
                formState.value = FormState.INITIAL;
                throw error;
            }
        }
    }
    return {
        isCallbackFormEnabled,
        uiSchema,
        isPending,
        runOnMounted,
        runOnBeforeUnmount,
        onSubmit
    };
}
