import { IconName } from '@leon-hub/icons';
import { FormState } from 'web/src/components/Form/enums';
import { useFormPhoneErrorPatterns } from 'web/src/components/Form/composables';
export default function getUiSchema(formState, phoneProductProperties, $t) {
    let buttonLabel = $t('WEB2_CALLBACK_REQUEST_BTN').value;
    switch(formState){
        case FormState.PENDING:
            buttonLabel = $t('WEB2_CALLBACK_SENDING_BTN').value;
            break;
        case FormState.SUCCESS:
            buttonLabel = $t('JS_CALLBACK_SUCCESS_TITLE').value;
            break;
        default:
            break;
    }
    const { phoneErrorPatterns } = useFormPhoneErrorPatterns();
    return {
        order: [
            'phone'
        ],
        submitButton: {
            label: buttonLabel,
            iconName: formState === FormState.SUCCESS ? IconName.CHECK_FILLED : void 0
        },
        fields: {
            phone: {
                title: $t('WEB2_PHONE_INPUT_LABEL').value,
                ...phoneProductProperties
            }
        },
        validatorErrorPatterns: {
            byWidget: phoneErrorPatterns.value
        }
    };
}
