import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { isString, isUndefined } from '@leon-hub/guards';
import RouteName from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika/src/constants';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { HelpChatType } from '@leon-hub/api-sdk';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { useSupportFaqStore } from 'web/src/modules/support/submodules/faq/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useLiveChatStore } from 'web/src/modules/live-chat/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useIntercomEvents } from 'web/src/modules/intercom/composables';
import { IntercomShowSpace } from 'web/src/modules/intercom/enums/IntercomShowSpace';
import windowOpen from 'web/src/utils/windowOpen';
import useSupport from 'web/src/modules/support/composables/useSupport';
export default function useSupportOptions() {
    const supportFaqStore = useSupportFaqStore();
    const siteconfigStore = useSiteConfigStore();
    const liveChatStore = useLiveChatStore();
    const router = useRouter();
    const bus = useEventsBus();
    const analytics = useAnalytics();
    const { showSpace } = useIntercomEvents();
    const { isLiveChatAvailable, isIntercomOptionsListFlow } = useSupport();
    const isLivechatAnonymEnabled = toRef(siteconfigStore, 'isLivechatAnonymEnabled');
    const isFaqBlockEnabled = toRef(siteconfigStore, 'isFaqBlockEnabled');
    const isHelpEmailEnabled = toRef(siteconfigStore, 'isHelpEmailEnabled');
    const helpChatType = toRef(siteconfigStore, 'helpChatType');
    const supportBlock = toRef(siteconfigStore, 'supportBlock');
    const liveChatEnabled = toRef(liveChatStore, 'enabled');
    const instagramUrl = toRef(()=>supportBlock.value?.helpInstagramUrl);
    const whatsappUrl = toRef(()=>supportBlock.value?.helpWhatsappUrl);
    const faqs = toRef(supportFaqStore, 'faqs');
    const betId = computed(()=>{
        const betIdQuery = router.getQuery('betId');
        isString(betIdQuery) || isUndefined(betIdQuery);
        return betIdQuery;
    });
    const chatEnabled = computed(()=>{
        if (isLiveChatAvailable.value) return liveChatEnabled.value;
        return helpChatType.value === HelpChatType.INTERCOM;
    });
    async function runOnMounted() {
        await supportFaqStore.fetchFaqCategories();
        if (supportFaqStore.isYandexMetrikaAllowed) analytics.push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
            supportDetails: {
                openHelp: 'visitHelpPage'
            }
        });
        supportFaqStore.setIsYandexMetrikaAllowed(true);
    }
    async function openChat() {
        if (isIntercomOptionsListFlow.value) {
            showSpace(IntercomShowSpace.MESSAGES);
            return;
        }
        router.closeModal();
        await nextAnimationFrame();
        bus.emit(BusEvent.OPEN_CHAT, {
            betId: betId.value
        });
    }
    function goToLoginPage() {
        if (isIntercomOptionsListFlow.value) {
            showSpace(IntercomShowSpace.MESSAGES);
            return;
        }
        liveChatStore.setOpenOnCreated(true);
        router.push({
            name: RouteName.LOGIN
        });
    }
    function openFeedback() {
        router.push({
            name: RouteName.FEEDBACK,
            query: {
                betId: betId.value
            }
        });
    }
    function openCallback() {
        router.push({
            name: RouteName.CALLBACK
        });
    }
    function openFAQ() {
        if (isIntercomOptionsListFlow.value) {
            showSpace(IntercomShowSpace.HELP);
            return;
        }
        router.push({
            name: RouteName.FAQ
        });
    }
    function openSocial(socialUrl) {
        if (instagramUrl.value || whatsappUrl.value) windowOpen(socialUrl);
    }
    function openFaqCategory(category) {
        analytics.push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
            supportDetails: {
                FAQ: {
                    clickSubject: category.title
                }
            }
        });
        router.push({
            name: RouteName.FAQ_CATEGORY,
            params: {
                category: category.urlId
            }
        });
    }
    return {
        isLivechatAnonymEnabled,
        isFaqBlockEnabled,
        isHelpEmailEnabled,
        chatEnabled,
        whatsappUrl,
        instagramUrl,
        faqs,
        runOnMounted,
        openChat,
        goToLoginPage,
        openFeedback,
        openCallback,
        openFAQ,
        openFaqCategory,
        openSocial
    };
}
