import RouteName from '@leon-hub/routing-config';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSiteConfigStore } from 'web/src/modules/core/store';
let SupportFaqPrefetch = class SupportFaqPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        try {
            const { isOldFaqEnabled } = useSiteConfigStore();
            if (!isOldFaqEnabled) {
                next({
                    name: RouteName.SUPPORT,
                    replace: true
                });
                return Promise.resolve();
            }
        } // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catch (error) {}
        //
        next();
        return Promise.resolve();
    }
};
export { SupportFaqPrefetch as default };
