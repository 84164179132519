export default function getFeedbackTypesForSchema(feedbackTypes) {
    const typesArray = [];
    const types = {};
    for (const type of feedbackTypes)if (type.key && type.title) {
        typesArray.push(type.key.toString());
        types[type.key] = type.title;
    }
    return {
        types,
        typesArray
    };
}
