import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { prepareSiteConfigHtml } from '@leon-hub/html-utils';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useSupportFaqStore } from 'web/src/modules/support/submodules/faq/store';
export default function useSupportFaqCategroyItem() {
    const router = useRouter();
    const supportFaqStore = useSupportFaqStore();
    const isFaqBlockEnabled = toRef(useSiteConfigStore(), 'isFaqBlockEnabled');
    const faqs = toRef(supportFaqStore, 'faqs');
    const categoryUrlId = computed(()=>String(router.getParam('category')) || '');
    const itemUrlId = computed(()=>String(router.getParam('item')) || '');
    const category = computed(()=>faqs.value.find((categ)=>categ.urlId === categoryUrlId.value));
    const categoryItems = computed(()=>category.value?.items ?? []);
    const item = computed(()=>categoryItems.value.find((it)=>it.urlId === itemUrlId.value));
    const content = computed(()=>prepareSiteConfigHtml(item.value?.content || ''));
    const title = computed(()=>item.value?.title || '');
    const metaParameters = computed(()=>({
            categoryItemTitle: item.value?.title || ''
        }));
    const runOnMounted = async ()=>{
        if (isFaqBlockEnabled.value) {
            if (!faqs.value.length) await supportFaqStore.fetchFaqCategories();
            if (!item.value) router.push({
                name: RouteName.ERROR_NOT_FOUND_404
            });
        } else router.push({
            name: RouteName.ERROR_FORBIDDEN_403
        });
    };
    return {
        metaParameters,
        title,
        content,
        runOnMounted
    };
}
