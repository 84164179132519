import RouteName from '@leon-hub/routing-config-names';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import useBaseIntercom from 'web/src/modules/intercom/composables/useBaseIntercom';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
let SupportCallbackPagePrefetch = class SupportCallbackPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        const input = useBaseIntercom().prefetch(from, next);
        if (!input) return;
        const { supportBlock } = useSiteConfigStore();
        const { isLoggedIn } = useIsLoggedIn();
        const callbackEnabled = isLoggedIn.value && supportBlock?.callbackEnabled && supportBlock?.isCallCenterWorking;
        if (!callbackEnabled) {
            next({
                name: RouteName.ERROR_FORBIDDEN_403
            });
            return;
        }
        next();
    }
};
export { SupportCallbackPagePrefetch as default };
