import { emailPattern } from 'web/src/components/Form/constants';
const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    title: 'Feedback',
    required: [
        'email',
        'type',
        'description'
    ],
    properties: {
        email: {
            type: 'string',
            pattern: emailPattern
        },
        type: {
            type: 'string',
            enum: [
                '0'
            ]
        },
        description: {
            type: 'string',
            maxLength: 1000
        }
    }
};
export default schema;
