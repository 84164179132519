import { IconName } from '@leon-hub/icons';
import { FormControlType, FormState } from 'web/src/components/Form/enums';
export default function getUiSchema(formState, email, types, $t) {
    let buttonLabel = $t('JSPNAV_CNT_SEND').value;
    switch(formState){
        case FormState.PENDING:
            buttonLabel = $t('WEB2_FEEDBACK_SENDING_BTN').value;
            break;
        case FormState.SUCCESS:
            buttonLabel = $t('JS_FEEDBACK_SUCCESS_TITLE').value;
            break;
        case FormState.ERROR:
            buttonLabel = $t('JSPNAV_CNT_SEND').value;
            break;
        default:
            break;
    }
    return {
        order: [
            'email',
            'type',
            'description'
        ],
        submitButton: {
            label: buttonLabel,
            iconName: formState === FormState.SUCCESS ? IconName.CHECK_FILLED : void 0
        },
        fields: {
            email: {
                title: $t('JSPNAV_CNT_YEMAIL').value,
                widget: FormControlType.Email,
                default: email
            },
            type: {
                widget: FormControlType.Select,
                options: {
                    label: $t('WEB2_FEEDBACK_FORM_THEME_INPUT').value,
                    placeholder: $t('WEB2_FEEDBACK_FORM_THEME_PLACEHOLDER').value
                },
                title: $t('WEB2_FEEDBACK_FORM_THEME_PLACEHOLDER').value,
                labels: types
            },
            description: {
                title: $t('WEB2_FEEDBACK_FORM_COMMENT_INPUT').value,
                widget: FormControlType.MultilineTextInput,
                options: {
                    rowsMax: 3
                }
            }
        }
    };
}
