import { defineStore } from 'pinia';
import { toRef } from 'vue';
import { doRequestCallback } from '@leon-hub/api-sdk/src/sdk/sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
const useSupportCallbackStore = defineStore('support-callback', ()=>{
    const apiClient = useGraphqlClient();
    const customerDataStore = useCustomerDataStore();
    const login = toRef(customerDataStore, 'login');
    async function requestCallback(phone) {
        await doRequestCallback(apiClient, (node)=>node.mutations.callback.request, {
            options: {
                phone,
                now: Date.now(),
                login: login.value
            }
        });
    }
    return {
        requestCallback
    };
});
export default useSupportCallbackStore;
