import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useSupportFaqStore } from 'web/src/modules/support/submodules/faq/store';
import { MIN_LENGTH_OF_SEARCH_TEXT } from 'web/src/modules/support/consts';
import useSupport from 'web/src/modules/support/composables/useSupport';
import StringUtils from 'web/src/utils/StringUtils';
import HighLighter from 'web/src/utils/HighLighter';
export default function useSupportFaq() {
    const supportFaqStore = useSupportFaqStore();
    const analytics = useAnalytics();
    const { faqEnabled } = useSupport();
    const router = useRouter();
    const faqCategories = toRef(supportFaqStore, 'faqCategories');
    const isYandexMetrikaAllowed = toRef(supportFaqStore, 'isYandexMetrikaAllowed');
    const searchText = toRef(supportFaqStore, 'searchText');
    const isHighlighted = (item)=>HighLighter.isHighlighted(item.title || '', searchText.value) || HighLighter.isHighlighted(item.text || '', searchText.value);
    const filteredCategories = computed(()=>{
        const categories = [];
        for (const category of faqCategories.value){
            const items = [];
            for (const item of category.items)if (isHighlighted(item)) items.push(item);
            if (items.length > 0) categories.push({
                name: category.name,
                items
            });
        }
        return categories;
    });
    const metaParameters = computed(()=>{
        const parser = new DOMParser();
        return {
            faqAnswers: {
                value: faqCategories.value.flatMap((category)=>category.items.map((item)=>({
                            text: StringUtils.escapeSpecialCharactersForJSON(item.title),
                            answer: StringUtils.escapeSpecialCharactersForJSON(parser.parseFromString(item.text, 'text/html').body.textContent)
                        })))
            }
        };
    });
    const openItem = (item, category)=>{
        analytics.push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
            supportDetails: {
                FAQ: {
                    openArticle: {
                        [category.name]: item.title
                    }
                }
            }
        });
    };
    const onInput = (value)=>{
        supportFaqStore.setSearchText(value);
    };
    const runOnMounted = async ()=>{
        if (!faqEnabled.value) router.push({
            name: RouteName.ERROR_FORBIDDEN_403
        });
        await supportFaqStore.fetchCategories();
        if (isYandexMetrikaAllowed.value) analytics.push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
            supportDetails: {
                FAQ: {
                    openFAQPage: true
                }
            }
        });
        supportFaqStore.setIsYandexMetrikaAllowed(true);
    };
    const runOnBeforeUnmount = ()=>{
        if (searchText.value.length > MIN_LENGTH_OF_SEARCH_TEXT && !filteredCategories.value.length) analytics.push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
            supportDetails: {
                FAQ: {
                    searchFAQ: {
                        [searchText.value]: false
                    }
                }
            }
        });
        supportFaqStore.setSearchText('');
    };
    return {
        openItem,
        onInput,
        filteredCategories,
        runOnMounted,
        runOnBeforeUnmount,
        metaParameters
    };
}
