function phoneSchemaProperties() {
    return {
        suffix: {
            type: 'string',
            pattern: '^[1-9][0-9]{4,14}$'
        },
        prefix: {
            type: 'string'
        }
    };
}
const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    title: 'Callback',
    required: [
        'phone'
    ],
    properties: {
        phone: {
            type: 'object',
            properties: phoneSchemaProperties(),
            required: [
                'suffix',
                'prefix'
            ]
        }
    }
};
export default schema;
