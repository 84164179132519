import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import useSupport from 'web/src/modules/support/composables/useSupport';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useSupportFaqStore } from 'web/src/modules/support/submodules/faq/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import HighLighter from 'web/src/utils/HighLighter';
export default function useSupportFaqCategory() {
    const router = useRouter();
    const supportFaqStore = useSupportFaqStore();
    const siteConfigStore = useSiteConfigStore();
    const eventBus = useEventsBus();
    const analytics = useAnalytics();
    const isFaqBlockEnabled = toRef(siteConfigStore, 'isFaqBlockEnabled');
    const faqs = toRef(supportFaqStore, 'faqs');
    const searchText = toRef(supportFaqStore, 'searchText');
    const { categoryByUrlId } = useSupport();
    const categoryUrlId = computed(()=>String(router.getParam('category')) || '');
    const category = computed(()=>categoryByUrlId(categoryUrlId.value));
    const metaParameters = computed(()=>({
            categoryTitle: category.value?.title || ''
        }));
    const isHighlighted = (item)=>HighLighter.isHighlighted(item.title || '', searchText.value.trim()) || HighLighter.isHighlighted(item.content || '', searchText.value.trim());
    const filteredItems = computed(()=>{
        const items = [];
        for (const item of category.value?.items ?? [])if (isHighlighted(item)) items.push(item);
        return items;
    });
    const onClick = (item)=>{
        analytics.push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
            supportDetails: {
                FAQ: {
                    openArticle: item.title
                }
            }
        });
        router.push({
            name: RouteName.FAQ_CATEGORY_ITEM,
            params: {
                item: item.urlId,
                category: categoryUrlId.value
            }
        });
        eventBus.emit(BusEvent.MODAL_CONTENT_SCROLL_TOP, {});
    };
    const setSearchText = (value)=>{
        supportFaqStore.setSearchText(value);
    };
    const runOnMounted = async ()=>{
        if (isFaqBlockEnabled.value) {
            if (!faqs.value.length) await supportFaqStore.fetchFaqCategories();
            if (!category.value) router.push({
                name: RouteName.ERROR_NOT_FOUND_404
            });
        } else router.push({
            name: RouteName.ERROR_FORBIDDEN_403
        });
    };
    return {
        metaParameters,
        runOnMounted,
        filteredItems,
        setSearchText,
        onClick
    };
}
