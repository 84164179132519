import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika/src/constants';
import RouteName from '@leon-hub/routing-config-names';
import { isString, isUndefined } from '@leon-hub/guards';
import { FormState } from 'web/src/components/Form/enums';
import useSupportFeedbackStore from 'web/src/modules/support/submodules/feedback/store/useSupportFeedbackStore';
import getFeedbackTypesForSchema from 'web/src/modules/support/submodules/feedback/pages/SupportFeedbackRoutePage/utils/getFeedbackTypesForSchema';
import feedbackSchema from 'web/src/modules/support/submodules/feedback/pages/SupportFeedbackRoutePage/schema/feedbackSchema';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { YMFeedbackActions } from 'web/src/modules/support/submodules/feedback/pages/SupportFeedbackRoutePage/types';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import getUiSchema from 'web/src/modules/support/submodules/feedback/pages/SupportFeedbackRoutePage/utils/getUiSchema';
export default function useSupportFeedback() {
    const loaded = ref(false);
    const formState = ref(FormState.INITIAL);
    const email = toRef(useCustomerDataStore(), 'email');
    const { $translate } = useI18n();
    const { showDialog, closeAllDialogs } = useDialogs();
    const feedbackStore = useSupportFeedbackStore();
    const feedbackTypes = toRef(feedbackStore, 'feedbackTypes');
    const router = useRouter();
    const analytics = useAnalytics();
    // eslint-disable-next-line max-len
    const feedbackTypesForSchema = computed(()=>getFeedbackTypesForSchema(feedbackTypes.value));
    const schema = computed(()=>{
        const formSchema = {
            ...feedbackSchema
        };
        const { typesArray } = feedbackTypesForSchema.value;
        if (typesArray.length) formSchema.properties.type.enum = typesArray;
        return formSchema;
    });
    const isPending = computed(()=>formState.value === FormState.PENDING);
    const uiSchema = computed(()=>getUiSchema(formState.value, email.value, feedbackTypesForSchema.value.types, $translate));
    function yandexMetrikaFeedback(action) {
        analytics.push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
            supportDetails: {
                email: {
                    [action]: true
                }
            }
        });
    }
    async function runOnMounted() {
        await feedbackStore.fetchTypes();
        loaded.value = true;
        yandexMetrikaFeedback(YMFeedbackActions.OPEN_EMAIL);
    }
    function runBeforeUnmount() {
        closeAllDialogs();
    }
    function showResponseModal() {
        const { promise, subscribe } = showDialog({
            presetName: PresetName.ALERT_SUCCESS,
            options: {
                confirmMessage: $translate('WEB2_FEEDBACK_SUCCESS_MESSAGE').value,
                title: $translate('JS_FEEDBACK_SUCCESS_TITLE').value,
                fullHeight: true,
                dataTestId: 'feedback-success-message'
            }
        });
        promise.then(()=>{});
        subscribe({
            [DialogAction.MODAL_CLOSE]: ()=>{
                "1";
                router.push({
                    name: RouteName.SUPPORT
                });
            }
        });
    }
    async function onSubmit(data) {
        if (data.errors || data.customErrors) formState.value = FormState.INITIAL;
        else {
            formState.value = FormState.PENDING;
            yandexMetrikaFeedback(YMFeedbackActions.SEND_EMAIL);
            try {
                const betId = router.getQuery('betId');
                isString(betId) || isUndefined(betId);
                const response = await feedbackStore.sendFeedback({
                    email: data.formData.email,
                    description: data.formData.description,
                    type: data.formData.type,
                    betId
                });
                formState.value = FormState.SUCCESS;
                if (response) showResponseModal();
            } catch (error) {
                formState.value = FormState.ERROR;
                throw error;
            }
        }
    }
    return {
        loaded,
        schema,
        isPending,
        uiSchema,
        onSubmit,
        runOnMounted,
        runBeforeUnmount
    };
}
