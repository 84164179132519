import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { onBeforeUnmount, onMounted } from 'vue';
import VSearchInput from 'web/src/components/SearchInput/VSearchInput/VSearchInput.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import useSupportFaqCategory from 'web/src/modules/support/submodules/faq/pages/SupportFaqCategoryRoutePage/composable/useSupportFaqCategory';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import useSearchText from 'web/src/modules/support/submodules/faq/composables/useSearchText';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SupportFaqCategoryRoutePage',
    setup (__props) {
        const { metaParameters, filteredItems, setSearchText, onClick, runOnMounted } = useSupportFaqCategory();
        const { searchText, highlightedText } = useSearchText();
        useSeoMetaParameters(metaParameters);
        onMounted(runOnMounted);
        onBeforeUnmount(()=>{
            setSearchText('');
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['faq'])
            }, [
                _createVNode(VSearchInput, {
                    value: _unref(searchText),
                    placeholder: _ctx.$t('WEB2_PLACEHOLDER_SEARCH_INPUT_FAQ'),
                    "is-autofocus": false,
                    class: _normalizeClass(_ctx.$style['faq__search']),
                    onInput: _unref(setSearchText),
                    onClear: _cache[0] || (_cache[0] = ($event)=>_unref(setSearchText)(''))
                }, null, 8, [
                    "value",
                    "placeholder",
                    "class",
                    "onInput"
                ]),
                _createVNode(VList, null, {
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredItems), (item)=>(_openBlock(), _createBlock(VListItem, {
                                    key: `${item.urlId}`,
                                    "have-expand": "",
                                    onClick: ($event)=>_unref(onClick)(item)
                                }, {
                                    inner: _withCtx(()=>[
                                            _createVNode(VDynamicContent, {
                                                content: _unref(highlightedText)(item.title)
                                            }, null, 8, [
                                                "content"
                                            ])
                                        ]),
                                    _: 2
                                }, 1032, [
                                    "onClick"
                                ]))), 128))
                        ]),
                    _: 1
                })
            ], 2)), [
                [
                    _directive_auto_id,
                    'SupportFaqCategoryRoutePage'
                ]
            ]);
        };
    }
});
