import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "faq-category-item"
};
const _hoisted_2 = {
    class: "faq-category-item__title"
};
import { onMounted } from 'vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import useSupportFaqCategroyItem from 'web/src/modules/support/submodules/faq/pages/SupportFaqCategoryItemRoutePage/composable/useSupportFaqCategoryItem';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SupportFaqCategoryItemRoutePage',
    setup (__props) {
        const { title, content, runOnMounted, metaParameters } = useSupportFaqCategroyItem();
        useSeoMetaParameters(metaParameters);
        onMounted(runOnMounted);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("h2", _hoisted_2, _toDisplayString(_unref(title)), 1),
                _createVNode(VDynamicContent, {
                    class: "faq-category-item__faq-content cms__content",
                    content: _unref(content)
                }, null, 8, [
                    "content"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'SupportFaqCategoryItemRoutePage'
                ]
            ]);
        };
    }
});
