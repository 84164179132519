import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { onBeforeUnmount, onMounted, ref } from 'vue';
import useSupportCallback from 'web/src/modules/support/submodules/callback/pages/SupportCallbackRoutePage/composables/useSupportCallback';
import schema from 'web/src/modules/support/submodules/callback/types/callbackSchema';
import { VForm } from 'web/src/components/Form';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SupportCallbackRoutePage',
    setup (__props) {
        const refForm = ref();
        function validateForm() {
            refForm.value?.showValidationErrors();
        }
        const { uiSchema, isCallbackFormEnabled, isPending, onSubmit, runOnBeforeUnmount, runOnMounted } = useSupportCallback();
        onMounted(runOnMounted);
        onBeforeUnmount(runOnBeforeUnmount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['callback']),
                "data-test-id": "callback-wrapper"
            }, [
                _createVNode(VCmsContent, {
                    "cms-key": "WEB2_CALL_CENTER",
                    "no-padding": "",
                    class: _normalizeClass(_ctx.$style['callback__text-content'])
                }, null, 8, [
                    "class"
                ]),
                _unref(isCallbackFormEnabled) ? (_openBlock(), _createBlock(_unref(VForm), {
                    key: 0,
                    ref_key: "refForm",
                    ref: refForm,
                    class: _normalizeClass(_ctx.$style['callback__form']),
                    schema: _unref(schema),
                    "ui-schema": _unref(uiSchema),
                    "is-pending": _unref(isPending),
                    onSubmit: _unref(onSubmit),
                    onFilled: validateForm
                }, null, 8, [
                    "class",
                    "schema",
                    "ui-schema",
                    "is-pending",
                    "onSubmit"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SupportCallbackRoutePage'
                ]
            ]);
        };
    }
});
