import { computed, toRef } from 'vue';
import { isOptionalString } from '@leon-hub/guards';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import useBaseIntercom from 'web/src/modules/intercom/composables/useBaseIntercom';
import { useLiveChatStore } from 'web/src/modules/live-chat/store';
import useSupport from 'web/src/modules/support/composables/useSupport';
let SupportCallbackPagePrefetch = class SupportCallbackPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        const input = useBaseIntercom().prefetch(from, next);
        const { isIntercomOptionsListFlow, isHelpMode } = useSupport();
        const isLiveChatFeatureEnabled = computed(()=>!isIntercomOptionsListFlow.value && void 0);
        if (!input) return;
        if (isLiveChatFeatureEnabled.value) {
            const bus = useEventsBus();
            const isChatActive = toRef(useLiveChatStore(), 'isChatActive');
            if (isChatActive.value) {
                const { betId } = to.query;
                isOptionalString(betId);
                if (isHelpMode.value) {
                    next();
                    return;
                }
                bus.emit(BusEvent.OPEN_CHAT, {
                    betId
                });
            }
        }
        next();
    }
};
export { SupportCallbackPagePrefetch as default };
