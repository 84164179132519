import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import { useSupportFaqStore } from 'web/src/modules/support/submodules/faq/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SupportModalTopBarRouteComponent',
    props: {
        title: {}
    },
    setup (__props) {
        const supportFaqStore = useSupportFaqStore();
        function onBack() {
            supportFaqStore.setIsYandexMetrikaAllowed(false);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: _ctx.title,
                onBack: onBack
            }, null, 8, [
                "title"
            ])), [
                [
                    _directive_auto_id,
                    'SupportModalTopBarRouteComponent'
                ]
            ]);
        };
    }
});
