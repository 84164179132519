import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "support-options-wrapper",
    "data-test-id": "support-options-wrapper"
};
import { onMounted } from 'vue';
import { IconName } from '@leon-hub/icons';
import useSupport from 'web/src/modules/support/composables/useSupport';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useSupportOptions from 'web/src/modules/support/submodules/options/pages/SupportOptionsRoutePage/composables/useSupportOptions';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import VListItemExtended from 'web/src/components/List/VListItemExtended/VListItemExtended.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import SubHeader from 'web/src/components/SubHeader/VSubHeader/VSubHeader.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SupportOptionsListRoutePage',
    setup (__props) {
        const { callbackEnabled, faqEnabled, isIntercomOptionsListFlow } = useSupport();
        const { isLoggedIn } = useIsLoggedIn();
        const { isLivechatAnonymEnabled, isFaqBlockEnabled, isHelpEmailEnabled, chatEnabled, whatsappUrl, instagramUrl, faqs, runOnMounted, openChat, goToLoginPage, openFeedback, openCallback, openFAQ, openFaqCategory, openSocial } = useSupportOptions();
        onMounted(runOnMounted);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _unref(isFaqBlockEnabled) ? (_openBlock(), _createBlock(SubHeader, {
                    key: 0,
                    text: _ctx.$t('WEB2_FAQ_BLOCK_TITLE')
                }, null, 8, [
                    "text"
                ])) : _createCommentVNode("", true),
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 2
                }, [
                    _unref(isFaqBlockEnabled) ? (_openBlock(), _createBlock(VList, {
                        key: 0,
                        class: "support-options-wrapper__support-faq-container"
                    }, {
                        default: _withCtx(()=>[
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(faqs), (category)=>(_openBlock(), _createBlock(VListItem, {
                                        key: `${category.urlId}`,
                                        title: category.title,
                                        "have-expand": "",
                                        onClick: ($event)=>_unref(openFaqCategory)(category)
                                    }, null, 8, [
                                        "title",
                                        "onClick"
                                    ]))), 128))
                            ]),
                        _: 1
                    })) : _createCommentVNode("", true)
                ], 64)),
                _createVNode(SubHeader, {
                    text: _ctx.$t('WEB2_CONTACT_SUPPORT_BLOCK_TITLE')
                }, null, 8, [
                    "text"
                ]),
                _createVNode(VList, null, {
                    default: _withCtx(()=>[
                            _unref(faqEnabled) ? (_openBlock(), _createBlock(VListItemExtended, {
                                key: 0,
                                "icon-name": _unref(IconName).QUESTION_OUTLINE,
                                title: _ctx.$t('JSP_FAQ_SUPPORT'),
                                "sub-title-first": _ctx.$t('WEB2_FAQ_SUPPORT_DESC'),
                                "data-test-id": "support-option-item-faq",
                                onClick: _unref(openFAQ)
                            }, null, 8, [
                                "icon-name",
                                "title",
                                "sub-title-first",
                                "onClick"
                            ])) : _createCommentVNode("", true),
                            _unref(chatEnabled) && _unref(isLoggedIn) ? (_openBlock(), _createBlock(VListItemExtended, {
                                key: 1,
                                "icon-name": _unref(IconName).CHAT_PROCESSING,
                                title: _ctx.$t('LIVECHAT_HEADER_NAME'),
                                "sub-title-first": _ctx.$t('WEB2_CHAT_SUPPORT_DESC'),
                                "data-test-id": "support-option-item-chat",
                                onClick: _unref(openChat)
                            }, null, 8, [
                                "icon-name",
                                "title",
                                "sub-title-first",
                                "onClick"
                            ])) : _createCommentVNode("", true),
                            _unref(whatsappUrl) ? (_openBlock(), _createBlock(VListItemExtended, {
                                key: 2,
                                "icon-name": _unref(IconName).SOCIAL_WHATSAPP_FILLED,
                                title: _ctx.$t('WEB2_WHATSAPP_CUSTOMER_SUPPORT_TITLE'),
                                "sub-title-first": _ctx.$t('WEB2_WHATSAPP_CUSTOMER_SUPPORT_DESC'),
                                "data-test-id": "support-option-item-whatsapp",
                                onClick: _cache[0] || (_cache[0] = ($event)=>_unref(openSocial)(_unref(whatsappUrl)))
                            }, null, 8, [
                                "icon-name",
                                "title",
                                "sub-title-first"
                            ])) : _createCommentVNode("", true),
                            _unref(isLivechatAnonymEnabled) && !_unref(isLoggedIn) ? (_openBlock(), _createBlock(VListItemExtended, {
                                key: 3,
                                "icon-name": _unref(IconName).CHAT_PROCESSING,
                                title: _ctx.$t('LIVECHAT_HEADER_NAME'),
                                "sub-title-first": _ctx.$t('WEB2_ANONYM_CHAT_SUPPORT_DESC'),
                                "data-test-id": "support-option-item-chat-anonym",
                                onClick: _unref(goToLoginPage)
                            }, null, 8, [
                                "icon-name",
                                "title",
                                "sub-title-first",
                                "onClick"
                            ])) : _createCommentVNode("", true),
                            _unref(callbackEnabled) ? (_openBlock(), _createBlock(VListItemExtended, {
                                key: 4,
                                "icon-name": _unref(IconName).PHONE,
                                title: _ctx.$t('WEB2_CALL_CUSTOMER_SUPPORT'),
                                "sub-title-first": _ctx.$t('WEB2_CALL_CUSTOMER_SUPPORT_DESC'),
                                "data-test-id": "support-option-item-callback",
                                onClick: _unref(openCallback)
                            }, null, 8, [
                                "icon-name",
                                "title",
                                "sub-title-first",
                                "onClick"
                            ])) : _createCommentVNode("", true),
                            _unref(isHelpEmailEnabled) ? (_openBlock(), _createBlock(VListItemExtended, {
                                key: 5,
                                "icon-name": _unref(IconName).MAIL,
                                title: _ctx.$t('WEB2_EMAIL_CUSTOMER_SUPPORT'),
                                "sub-title-first": _ctx.$t('WEB2_EMAIL_CUSTOMER_SUPPORT_DESC'),
                                "data-test-id": "support-option-item-feedback",
                                onClick: _unref(openFeedback)
                            }, null, 8, [
                                "icon-name",
                                "title",
                                "sub-title-first",
                                "onClick"
                            ])) : _createCommentVNode("", true),
                            _unref(instagramUrl) ? (_openBlock(), _createBlock(VListItemExtended, {
                                key: 6,
                                "icon-name": _unref(IconName).SOCIAL_INSTAGRAM_FILLED,
                                title: _ctx.$t('WEB2_INSTAGRAM_CUSTOMER_SUPPORT_TITLE'),
                                "sub-title-first": _ctx.$t('WEB2_INSTAGRAM_CUSTOMER_SUPPORT_DESC'),
                                "data-test-id": "support-option-item-instagram",
                                onClick: _cache[1] || (_cache[1] = ($event)=>_unref(openSocial)(_unref(instagramUrl)))
                            }, null, 8, [
                                "icon-name",
                                "title",
                                "sub-title-first"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 1
                })
            ])), [
                [
                    _directive_auto_id,
                    'SupportOptionsListRoutePage'
                ]
            ]);
        };
    }
});
