import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { onBeforeUnmount, onMounted } from 'vue';
import VSearchInput from 'web/src/components/SearchInput/VSearchInput/VSearchInput.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import useSupportFaq from 'web/src/modules/support/submodules/faq/pages/SupportFaqRoutePage/composables/useSupportFaq';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import useSearchText from 'web/src/modules/support/submodules/faq/composables/useSearchText';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SupportFaqRoutePage',
    setup (__props) {
        const { openItem, onInput, runOnMounted, runOnBeforeUnmount, filteredCategories, metaParameters } = useSupportFaq();
        const { highlightedText, searchText } = useSearchText();
        onMounted(runOnMounted);
        onBeforeUnmount(runOnBeforeUnmount);
        useSeoMetaParameters(metaParameters);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['faq']),
                "data-test-id": "support-faq-wrapper"
            }, [
                _createVNode(VSearchInput, {
                    value: _unref(searchText),
                    placeholder: _ctx.$t('WEB2_PLACEHOLDER_SEARCH_INPUT_FAQ'),
                    "is-autofocus": false,
                    class: _normalizeClass(_ctx.$style['faq__input']),
                    onInput: _unref(onInput),
                    onClear: _cache[0] || (_cache[0] = ($event)=>_unref(onInput)(''))
                }, null, 8, [
                    "value",
                    "placeholder",
                    "class",
                    "onInput"
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredCategories), (category)=>(_openBlock(), _createElementBlock("div", {
                        key: category.name
                    }, [
                        _createElementVNode("h4", null, _toDisplayString(category.name), 1),
                        _createVNode(VList, null, {
                            default: _withCtx(()=>[
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.items, (item)=>(_openBlock(), _createBlock(VListItemAccordion, {
                                            id: `${category.name}--${item.id}`,
                                            key: `${category.name}--${item.id}`,
                                            title: _unref(highlightedText)(item.title),
                                            onClick: ($event)=>_unref(openItem)(item, category)
                                        }, {
                                            inner: _withCtx(()=>[
                                                    _createVNode(VDynamicContent, {
                                                        content: _unref(highlightedText)(item.title)
                                                    }, null, 8, [
                                                        "content"
                                                    ])
                                                ]),
                                            content: _withCtx(()=>[
                                                    _createElementVNode("div", {
                                                        class: _normalizeClass(_ctx.$style['faq__accordion-content-wrapper'])
                                                    }, [
                                                        _createVNode(VDynamicContent, {
                                                            content: _unref(highlightedText)(item.text)
                                                        }, null, 8, [
                                                            "content"
                                                        ])
                                                    ], 2)
                                                ]),
                                            _: 2
                                        }, 1032, [
                                            "id",
                                            "title",
                                            "onClick"
                                        ]))), 128))
                                ]),
                            _: 2
                        }, 1024)
                    ]))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'SupportFaqRoutePage'
                ]
            ]);
        };
    }
});
